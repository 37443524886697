// extracted by mini-css-extract-plugin
export var root = "PlasmicFeatures-module--root--2CVwz";
export var box___8Sojj = "PlasmicFeatures-module--box___8Sojj--1Cz-l";
export var header = "PlasmicFeatures-module--header--aLgU4";
export var section = "PlasmicFeatures-module--section--YTT7P";
export var box__q7LTb = "PlasmicFeatures-module--box__q7LTb--1sjgX";
export var box___1L3Jj = "PlasmicFeatures-module--box___1L3Jj--3UFXs";
export var box___8G9O6 = "PlasmicFeatures-module--box___8G9O6--203fH";
export var box__iumjS = "PlasmicFeatures-module--box__iumjS--2z-sk";
export var bullet__pyw1O = "PlasmicFeatures-module--bullet__pyw1O--32eBP";
export var bullet__aAhqY = "PlasmicFeatures-module--bullet__aAhqY--3nBa8";
export var bullet__rda90 = "PlasmicFeatures-module--bullet__rda90--2fidE";
export var box__lDKee = "PlasmicFeatures-module--box__lDKee--1Is8k";
export var box__k9KUq = "PlasmicFeatures-module--box__k9KUq--Wz2_G";
export var box__kxMjc = "PlasmicFeatures-module--box__kxMjc--21-J1";
export var img__xzeCu = "PlasmicFeatures-module--img__xzeCu--R74Jr";
export var img__lFTpL = "PlasmicFeatures-module--img__lFTpL--2n1G8";
export var homeCta = "PlasmicFeatures-module--homeCta--_VZ3o";
export var footer = "PlasmicFeatures-module--footer--3Mue7";