// extracted by mini-css-extract-plugin
export var root = "PlasmicPlan-module--root--1Vhcn";
export var box__f4Eyv = "PlasmicPlan-module--box__f4Eyv--1NcCA";
export var box__czn4P = "PlasmicPlan-module--box__czn4P--SRgKB";
export var slotName = "PlasmicPlan-module--slotName--3Vdmj";
export var box___5UbOq = "PlasmicPlan-module--box___5UbOq--wpf62";
export var box__ur5NT = "PlasmicPlan-module--box__ur5NT--1uoBd";
export var bullet__gJ6Yv = "PlasmicPlan-module--bullet__gJ6Yv--2sVEg";
export var bullet__t3BeX = "PlasmicPlan-module--bullet__t3BeX--3nejo";
export var bullet__hY3OE = "PlasmicPlan-module--bullet__hY3OE--1eJKG";
export var box__poCpy = "PlasmicPlan-module--box__poCpy--2LFoF";
export var svg__hms2J = "PlasmicPlan-module--svg__hms2J--3RGuR";