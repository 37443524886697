// extracted by mini-css-extract-plugin
export var root = "PlasmicFeatureCard-module--root--2o9Zx";
export var root__long = "PlasmicFeatureCard-module--root__long--1alJX";
export var box__l0Xvq = "PlasmicFeatureCard-module--box__l0Xvq--1wtR8";
export var box__wlty9 = "PlasmicFeatureCard-module--box__wlty9--2AlEn";
export var svg__antst = "PlasmicFeatureCard-module--svg__antst--1Xy6K";
export var box__sMePy = "PlasmicFeatureCard-module--box__sMePy--E2ZS8";
export var box__long__sMePYqqAI = "PlasmicFeatureCard-module--box__long__sMePYqqAI--2usnw";
export var slotTitle = "PlasmicFeatureCard-module--slotTitle--f-M6A";
export var slotDescription = "PlasmicFeatureCard-module--slotDescription--ek_se";
export var slotDescription__long = "PlasmicFeatureCard-module--slotDescription__long--1CZ_e";
export var box___94UV = "PlasmicFeatureCard-module--box___94UV--3PXhU";
export var box__long___94UVqqAI = "PlasmicFeatureCard-module--box__long___94UVqqAI--2jpD-";
export var svg__jO6Fg = "PlasmicFeatureCard-module--svg__jO6Fg--3dtB4";