// extracted by mini-css-extract-plugin
export var root = "PlasmicTopSection-module--root--2B2t-";
export var box__b6Y0K = "PlasmicTopSection-module--box__b6Y0K--2Vcly";
export var box__iXlZa = "PlasmicTopSection-module--box__iXlZa--3B1Hn";
export var box__wnTe0 = "PlasmicTopSection-module--box__wnTe0--2Mf7M";
export var box__txZtJ = "PlasmicTopSection-module--box__txZtJ--1DGy4";
export var box___6Adyu = "PlasmicTopSection-module--box___6Adyu--2KmBA";
export var box__pnmbw = "PlasmicTopSection-module--box__pnmbw--YXSdi";
export var box__yqsoF = "PlasmicTopSection-module--box__yqsoF--1JCil";
export var linkButton__hf190 = "PlasmicTopSection-module--linkButton__hf190--1dNxM";
export var linkButton___3LFjd = "PlasmicTopSection-module--linkButton___3LFjd--3fZ_9";
export var box__pd2Mc = "PlasmicTopSection-module--box__pd2Mc--8a0Ii";
export var img = "PlasmicTopSection-module--img--TX3Qd";