// extracted by mini-css-extract-plugin
export var root = "PlasmicPricing-module--root--Kf0oH";
export var box__cyyhy = "PlasmicPricing-module--box__cyyhy--2TPFd";
export var header = "PlasmicPricing-module--header--3rvc5";
export var section__dcWe4 = "PlasmicPricing-module--section__dcWe4--dXIz0";
export var box__hwl2W = "PlasmicPricing-module--box__hwl2W--3yrvj";
export var plan__stK2 = "PlasmicPricing-module--plan__stK2--3hQ-I";
export var box__zkYp8 = "PlasmicPricing-module--box__zkYp8--2KDTY";
export var box__xQcgv = "PlasmicPricing-module--box__xQcgv--1rtcq";
export var bullet__tiiA = "PlasmicPricing-module--bullet__tiiA--wLwBX";
export var bullet__cFkWa = "PlasmicPricing-module--bullet__cFkWa--1VBiF";
export var bullet__zY8Ll = "PlasmicPricing-module--bullet__zY8Ll--Gl_4E";
export var plan__gVyFc = "PlasmicPricing-module--plan__gVyFc--3bg24";
export var box___0RV21 = "PlasmicPricing-module--box___0RV21--1TOpe";
export var box__bOrvc = "PlasmicPricing-module--box__bOrvc--187nE";
export var bullet__ufLu2 = "PlasmicPricing-module--bullet__ufLu2--2Tj7T";
export var bullet__faXqH = "PlasmicPricing-module--bullet__faXqH--rBoIT";
export var bullet__i0K6O = "PlasmicPricing-module--bullet__i0K6O--3cv7s";
export var bullet__hsfya = "PlasmicPricing-module--bullet__hsfya--3MOAS";
export var plan__kdmDe = "PlasmicPricing-module--plan__kdmDe--1hBy-";
export var box__ejzWu = "PlasmicPricing-module--box__ejzWu--2jJzU";
export var box__fmuB = "PlasmicPricing-module--box__fmuB--1hWl5";
export var bullet__qqYoD = "PlasmicPricing-module--bullet__qqYoD--2t-56";
export var bullet__xVsdg = "PlasmicPricing-module--bullet__xVsdg--2tABZ";
export var bullet__lz9P = "PlasmicPricing-module--bullet__lz9P--1aLex";
export var section__xQh15 = "PlasmicPricing-module--section__xQh15--3iiJx";
export var faq__z9Bcj = "PlasmicPricing-module--faq__z9Bcj--3XL-v";
export var faq__dBNsM = "PlasmicPricing-module--faq__dBNsM--hRL9c";
export var faq__dVtAp = "PlasmicPricing-module--faq__dVtAp--1LPdH";
export var footer = "PlasmicPricing-module--footer--zhosD";