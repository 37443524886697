// extracted by mini-css-extract-plugin
export var root = "PlasmicFooter-module--root--2Nkxw";
export var box__tyCn3 = "PlasmicFooter-module--box__tyCn3--1_jSn";
export var box__lrRwv = "PlasmicFooter-module--box__lrRwv--hrHtO";
export var box__tAclb = "PlasmicFooter-module--box__tAclb--1qqM9";
export var iconLink__zQt1 = "PlasmicFooter-module--iconLink__zQt1--1aJAv";
export var svg__ijR1 = "PlasmicFooter-module--svg__ijR1--3wZqM";
export var box__wnTa9 = "PlasmicFooter-module--box__wnTa9--2QEAZ";
export var link__vsVyw = "PlasmicFooter-module--link__vsVyw--2psCr";
export var box__apdX4 = "PlasmicFooter-module--box__apdX4--32yTE";
export var link___5KiK5 = "PlasmicFooter-module--link___5KiK5--3BGVd";
export var box___7RPas = "PlasmicFooter-module--box___7RPas--3De0b";
export var box__ulGhq = "PlasmicFooter-module--box__ulGhq--1e_aN";
export var box__bdQzo = "PlasmicFooter-module--box__bdQzo--2Z0bR";
export var link__hKboq = "PlasmicFooter-module--link__hKboq--3z_kl";
export var link__ndovs = "PlasmicFooter-module--link__ndovs--2OuH7";
export var link__blVk = "PlasmicFooter-module--link__blVk--4KWLR";
export var link___7QOdl = "PlasmicFooter-module--link___7QOdl--2xYbE";
export var link__ryN92 = "PlasmicFooter-module--link__ryN92--2N5az";
export var box__cCMiK = "PlasmicFooter-module--box__cCMiK---2bs-";
export var box__eCyBq = "PlasmicFooter-module--box__eCyBq--Snwvd";
export var link__vtjy9 = "PlasmicFooter-module--link__vtjy9--1wwr8";
export var link__xmMhH = "PlasmicFooter-module--link__xmMhH--HNJYs";
export var link__muXi = "PlasmicFooter-module--link__muXi--sbjpt";
export var link__zx72Q = "PlasmicFooter-module--link__zx72Q--2zZ7W";
export var link__ga51D = "PlasmicFooter-module--link__ga51D--3Z2Nm";
export var box___686Ym = "PlasmicFooter-module--box___686Ym--3kSIo";
export var box__mIDoU = "PlasmicFooter-module--box__mIDoU--25Z73";
export var link__bmdzi = "PlasmicFooter-module--link__bmdzi--1wPKy";
export var link___2NOvJ = "PlasmicFooter-module--link___2NOvJ--RoaF0";
export var link__o7P4K = "PlasmicFooter-module--link__o7P4K--2AHr2";
export var link__js5Zr = "PlasmicFooter-module--link__js5Zr--2uMT1";
export var link__kbOc4 = "PlasmicFooter-module--link__kbOc4--3Q6AX";
export var box__l9VuR = "PlasmicFooter-module--box__l9VuR--2gfyH";
export var box__dDb8W = "PlasmicFooter-module--box__dDb8W--1b8K_";
export var box__o0XiN = "PlasmicFooter-module--box__o0XiN--3bhy9";
export var box___5Hl8B = "PlasmicFooter-module--box___5Hl8B--3aiok";
export var textbox = "PlasmicFooter-module--textbox--2iVbX";
export var button = "PlasmicFooter-module--button--Lj5uW";
export var box__wmMh = "PlasmicFooter-module--box__wmMh--3PZFn";
export var box__zfrj5 = "PlasmicFooter-module--box__zfrj5--3kMwv";
export var box__pjtOt = "PlasmicFooter-module--box__pjtOt--15a-B";
export var link__qUiu5 = "PlasmicFooter-module--link__qUiu5--T3pHG";
export var link__bHyNw = "PlasmicFooter-module--link__bHyNw--2vupB";
export var box__xg5Kv = "PlasmicFooter-module--box__xg5Kv--2YUSI";
export var box__pbvQo = "PlasmicFooter-module--box__pbvQo--2Fy3f";
export var iconLink___6I6TS = "PlasmicFooter-module--iconLink___6I6TS--2TWV3";
export var svg__syqZz = "PlasmicFooter-module--svg__syqZz--3hMRr";
export var iconLink__eidqr = "PlasmicFooter-module--iconLink__eidqr--1_t30";
export var svg___8Nq65 = "PlasmicFooter-module--svg___8Nq65--1pWUm";
export var iconLink___1EYoE = "PlasmicFooter-module--iconLink___1EYoE--3GtUd";
export var svg__zRwxK = "PlasmicFooter-module--svg__zRwxK--1KlDz";